<template>
  <div id="scheme-selector"
    v-on-click-outside="hide"
  >
    <span class='your-scheme'>Your scheme:</span>
    <div class='schemes-dropdown'>
      <button @click="toggleSelectorIsActive" class='schemes-dropdown__button' aria-label='scheme selector dropdown button'>{{ selectedScheme.name }}</button>
      <ul
        class='schemes-dropdown__list'
        v-bind:class="selectorIsActive ? 'schemes-dropdown__list--is-active' : ''">
        <li
          class='schemes-dropdown__list-item'
          v-for="scheme in visibleSchemes"
          v-bind:value="scheme"
          v-bind:key="scheme.name"
          @click="navigateTo(scheme.url);">
          {{ scheme.name }}
        </li>
        <li
          class='schemes-dropdown__list-item schemes-dropdown__list-item--border-top'
          v-if="isChatboxUser && schemesAreAvailable"
          @click="navigateToHome"
        >
          Not right? Ask TOBi
        </li>
        <li
          class='schemes-dropdown__list-item schemes-dropdown__list-item--border-top'
          v-else-if="!schemesAreAvailable"
          @click="navigateToHome"
        >
          Not sure? Ask TOBi
        </li>
        <li class='schemes-dropdown__list-item schemes-dropdown__list-item--border-top'
          @click="navigateTo('/all_schemes');"
        >
          All schemes
        </li>
      </ul>
    </div>
    <transition name="fade">
      <div v-if="showSchemeSwitchNotification" class='switch-notification'>
        <div class='switch-notification__margins'>
          <span class='switch-notification__arrow'></span>
          <div class='switch-notification__box'>
            <button
              @click="setHasSeenSwitchNotification()"
              class='switch-notification__button'>&times;</button>
            <span class='switch-notification__title'>Hello,</span>
            <span class='switch-notification__text'>If you want to change your scheme at any time you can use the scheme selector.</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedScheme: { name: "No scheme selected", url: "/" },
      selectorIsActive: false,
      noAvailableScheme: { name: "No scheme selected", url: "/" },
      cookieData: {},
      isChatboxUser: null,
      schemes: [
        { name: "LifeSight", url: "/dc", show: false },
        { name: "VGPS", url: "/db/vgps", show: false },
        { name: "THUS", url: "/db/thus", show: false }
      ],
      showSchemeSwitchNotification: null,
      homePagePath: '/',
    };
  },
  computed: {
    schemeFromPath() {
      const currentScheme = this.visibleSchemes.find(scheme =>
        window.location.href.includes(scheme.url)
      );
      if (currentScheme) {
        return currentScheme;
      } else {
        return this.noAvailableScheme;
      }
    },
    visibleSchemes() {
      return this.schemes.filter(scheme => scheme.show === true);
    },
    schemesAreAvailable() {
      return this.visibleSchemes.length > 0;
    }
  },
  methods: {
    setSelectedScheme() {
      this.selectedScheme = this.schemeFromPath;
    },
    toggleSelectorIsActive() {
      if (this.selectorIsActive === true) {
        this.selectorIsActive = false;
      } else {
        this.selectorIsActive = true;
      }
    },
    hide() {
      this.selectorIsActive = false;
    },
    navigateTo(url) {
      const pathName = window.location.pathname;

      if (pathName === url) {
        // don't navigate to if already on that page
        return;
      }
      window.location = url;
    },
    navigateToHome() {
      window.location = this.homePagePath;
    },
    setHasSeenSwitchNotification() {
      this.showSchemeSwitchNotification = false;
      this.$cookies.set("hasSeenSchemeSwitchNotification", true, Infinity);
    },
    setShowSchemeSwitchNotification() {
      // hide 'popup' if in urlsWherePopupShouldNotShow
      const currentPath = window.location.pathname
      const pathsWherePopupShouldNotShow = [this.homePagePath];
      const thisPageIsAllowedToShowPopup = pathsWherePopupShouldNotShow.indexOf(currentPath) === -1;

      if (this.$cookies.get("hasSeenSchemeSwitchNotification")) {
        this.showSchemeSwitchNotification = false;
      } else if (thisPageIsAllowedToShowPopup === false) {
        this.showSchemeSwitchNotification = false;
      } else {
        setTimeout(() => {
          this.showSchemeSwitchNotification = true;
        }, 500)
      }
    },
  },
  watch: {
    showSchemeSwitchNotification() {
      if (!this.showSchemeSwitchNotification) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      } else {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
      }
    }
  },
  created() {
    if (this.$cookies.get("schemes")) {
      this.cookieData = this.$cookies.get("schemes");

      if (this.cookieData.lifesight) {
        this.schemes[0].show = true;
      }

      if (this.cookieData.vgps) {
        this.schemes[1].show = true;
      }

      if (this.cookieData.thus) {
        this.schemes[2].show = true;
      }

      if (this.cookieData.isChatboxUser) {
        this.isChatboxUser = true;
      }
    }
    this.setSelectedScheme();
    this.setShowSchemeSwitchNotification();
  }
};
</script>

<style lang="scss" scoped>
#scheme-selector {
  border: 1px solid #999;
  margin: 10px 0;
  padding: 3px;
}

a {
  color: white;
}

span,
p {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.your-scheme {
  display: inline-block;
  font-weight: bold;
  z-index: 3;
  font-family: "Vodafone";
}

.switch-notification {
  position: absolute;
  z-index: 1;
  left: 0;
  // TODO: do this a better way...
  top: 58px;

  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.7);
  line-height: initial;
  // TODO: do this a better way...
  @media (max-width: 580px) {
    top: 84px;
  }
  &__margins {
    padding: 20px;
    width: 100%;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  &__box {
    margin-top: 150px;
    position: relative;
    max-width: 500px;
    width: 100%;
    padding-right: 100px;
    @media (max-width: 680px) {
      margin-left: 100px;
      margin-top: 110px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: -170px;
      display: inline-block;
      background-image: url("images/tobi.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 150px;
      height: 150px;
      border-radius: 100%;
      @media (max-width: 680px) {
        width: 90px;
        height: 90px;
        left: -97px;
      }
    }
  }
  &__text {
    margin-top: 20px;
    font-size: 2.5rem;
    display: block;
    font-weight: bold;
    @media (max-width: 680px) {
      font-size: 1.5rem;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 5rem;
    display: block;
    @media (max-width: 680px) {
      font-size: 3rem;
    }
  }
  &__button {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    border: none;
    background: none;
    height: 70px;
    width: 70px;
    font-size: 4rem;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 680px) {
      font-size: 2.5rem;
      width: 30px;
      height: 30px;
    }
  }
  &__arrow {
    display: inline-block;
    position: absolute;
    background-image: url("images/scheme_switch_arrow.svg");
    background-repeat: no-repeat;
    height: 200px;
    width: 94px;
    top: 10px;
    right: 100px;
    @media (max-width: 680px) {
      height: 160px;
      width: 85px;
      top: 10px;
      right: 50px;
    }
  }
}


.schemes-dropdown {
  display: inline-block;
  background-color: white;
  position: relative;
  color: #333;
  margin-left: 10px;
  padding: 0px 5px 0px 5px;
  min-width: 150px;;
  border: 1px solid #333;
  &__button {
    position: relative;
    background: none;
    text-align: left;
    border: none;
    width: 100%;
    font-family: "Vodafone";
    font-size: 1rem;
    padding: 5px;
    min-width: 170px;
    &:hover {
      cursor: pointer;
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("images/scheme-selector-down-arrow.svg");
      width: 18px;
      height: 18px;
      background-position: center center;
      background-repeat: no-repeat;
      right: 5px;
      top: 50%;
      transform: (translateY(-50%));
    }
  }
  &__list {
    background-color: white;
    position: absolute;
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    left: -1px;
    right: -1px;
    transition: max-height 0.3s ease-in-out;
    z-index: 100;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
    &--is-active {
      max-height: 400px;
    }
  }
  &__list-item {
    padding: 10px;
    &--border-top {
      border-top: 1px solid #333;
    }
    &:hover {
      color: #e60000;
      cursor: pointer;
      background-color: #f3f3f3;
    }
  }
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
