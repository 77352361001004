function addToggleActiveOnClickToAccordions() {
  const accordionButtons = document.querySelectorAll('.accordion__button');
  accordionButtons.forEach(accordionButton => {
    accordionButton.addEventListener('click', () => {
      accordionButton.parentElement.classList.toggle('active');
    });
  });
}

document.addEventListener('turbolinks:load', () => {
  addToggleActiveOnClickToAccordions();
});