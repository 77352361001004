function setBurgerMenuButton() {
  const navButton = document.querySelector('.navigation-button');
  if (navButton) {
    navButton.addEventListener('click', () => {
      const navBar = document.querySelector('.navigation');
      navButton.classList.toggle('active');
      navBar.classList.toggle('active');
    });
  }
}

document.addEventListener('turbolinks:load', () => {
  setBurgerMenuButton();
})