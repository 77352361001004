<template>
  <div id="scheme-selection-chatbox">
    <header class='tobi-header'>
      <div class='tobi-image'></div>
      <h1 class='tobi-title'>Hi there, I'm TOBi.</h1>
      <h2 class='tobi-subtitle'>I’m here to find out which scheme you’re in so you get the information that’s right for you. If you know which scheme you are in you can select from the above menu or answer ‘Yes’ to the below question.</h2>
    </header>

    <div class='chatbox-container'>
      <div class='question' v-if="currentQuestion === 1">
        <speech-bubble :text="questions.one" />
        <label class="option-bubble in-right">
          <input type="radio" name="question_1" v-bind:value="true" v-model="knowsWhichScheme"> Yes
          <span class="box"></span>
        </label>
        <label class="option-bubble in-right">
          <input type="radio" name="question_1" v-bind:value="false" v-model="knowsWhichScheme"> No
          <span class="box"></span>
        </label>
        <label class="option-bubble in-right">
          <input type="radio" name="question_1" v-bind:value="constants.notInSchemeYet" v-model="knowsWhichScheme"> {{ constants.notInSchemeYet }}
          <span class="box"></span>
        </label>
        <label class="option-bubble in-right">
          <input type="radio" name="question_1" v-bind:value="constants.skipToAllSchemes" v-model="knowsWhichScheme"> {{ constants.skipToAllSchemes }}
          <span class="box"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--red in-down continue-button' @click="submitQuestionOne()" v-if="knowsWhichScheme !== null">Continue</button>
        </div>
      </div>

      <div class='question' v-if="currentQuestion === 2">
        <speech-bubble :text="questions.two" />
        <label
          v-for="scheme in schemes"
          v-bind:key="scheme.value"
          v-bind:class="['option-bubble', 'in-right', { 'option-bubble--disabled': scheme.disabledInQuestion2 } ]">
          <input
            type="checkbox"
            name="question_2"
            v-bind:value="scheme"
            v-model="checkedSchemes"
            :disabled="scheme.disabledInQuestion2"
            @change="handleKnowsWhichSchemeCheck"> {{ scheme.name }}
          <span class="box box--squares"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--grey back-button in-down' @click="goToQuestion(1)">Back</button>
          <button class='button button--red in-down continue-button' @click="submitQuestionTwo()" v-if="checkedSchemes.length !== 0">Continue</button>
        </div>
      </div>


      <div class='question' v-if="currentQuestion === 3">
        <speech-bubble :text="questions.three" />
        <label class="option-bubble in-right">
          <input type="radio" name="question_3" v-bind:value="true" v-model="isCurrentlyPayingIn"> Yes
          <span class="box"></span>
        </label>
        <label class="option-bubble in-right">
          <input type="radio" name="question_3" v-bind:value="false" v-model="isCurrentlyPayingIn"> No
          <span class="box"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--grey back-button in-down' @click="goToQuestion(1)">Back</button>
          <button class='button button--red in-down continue-button' @click="submitQuestionThree()" v-if="isCurrentlyPayingIn !== null">Continue</button>
        </div>
      </div>


      <div class='question' v-if="currentQuestion === 4">
        <speech-bubble :text="questions.four" />
        <label class="option-bubble in-right">
          <input type="radio" name="question_4" v-bind:value="true" v-model="startedWorkingForVodafoneBeforeJan06"> Yes
          <span class="box"></span>
        </label>
        <label class="option-bubble in-right">
          <input type="radio" name="question_4" v-bind:value="false" v-model="startedWorkingForVodafoneBeforeJan06"> No
          <span class="box"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--grey back-button in-down' @click="goToQuestion(3)">Back</button>
          <button class='button button--red in-down continue-button' @click="submitQuestionFour()" v-if="startedWorkingForVodafoneBeforeJan06 !== null">Continue</button>
        </div>
      </div>

      <div class='question' v-if="currentQuestion === 5">
        <speech-bubble :text="questions.five" />
        <label v-for="company in thusCompanies.concat('I worked for none of these')"
          v-bind:key="company" class="option-bubble in-right"
        >
          <input type="radio" name="question_5" v-bind:value="company" v-model="checkedCompanyTHUS"> {{ company }}
          <span class="box"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--grey back-button in-down' @click="goToQuestion(4)">Back</button>
          <button
            class='button button--red in-down continue-button'
            @click="submitQuestionFive()" v-if="checkedCompanyTHUS !== null">Continue
          </button>
        </div>
      </div>

      <div class='question' v-if="currentQuestion === 6">
        <speech-bubble :text="questions.six" />
        <label v-for="company in thusCompanies.concat(vgpsCompanies).concat('I worked for none of these')"
          v-bind:key="company" class="option-bubble in-right"
        >
          <input type="radio" name="question_6" v-bind:value="company" v-model="checkedCompanyALL"> {{ company }}
          <span class="box"></span>
        </label>
        <div class='form-buttons'>
          <button class='button button--grey back-button in-down' @click="goToQuestion(4)">Back</button>
          <button
            class='button button--red in-down continue-button'
            @click="submitQuestionSix()" v-if="checkedCompanyALL !== null">Continue
          </button>
        </div>
      </div>

      <div class='end-of-chat' v-if="currentQuestion === null">
        <speech-bubble :text="currentUserSchemes.message" />

        <div class='scheme-links' v-if="showSchemeLinks">
          <a v-if="currentUserSchemes.lifesight" href='/dc' class='button button--red in-right'>Go to LifeSight</a>
          <a v-if="currentUserSchemes.vgps || currentUserSchemes.jgts" href='/db/vgps' class='button button--red in-right'>Go to VGPS</a>
          <a v-if="currentUserSchemes.thus" href='/db/thus' class='button button--red in-right'>Go to THUS</a>
          <a v-if="currentUserSchemes.customButton && currentUserSchemes.customButton.show" :href='currentUserSchemes.customButton.href' class='button button--red in-right'>{{ currentUserSchemes.customButton.text }}</a>
        </div>
        <p>Not right, made a mistake? You can <a @click="resetChat()"> start again</a>.</p>
      </div>

    </div>



  </div>
</template>

<script>
import SpeechBubble from "./components/SpeechBubble.vue";
export default {
  components: { SpeechBubble },
  data() {
    return {
      knowsWhichScheme: null,
      isCurrentlyPayingIn: null,
      startedWorkingForVodafoneBeforeJan06: null,
      checkedSchemes: [],
      currentQuestion: 1,
      schemes: [
        {
          name: "LifeSight - the DC pension plan",
          value: "LifeSight",
          url: "/dc",
          disabledInQuestion2: false
        },
        {
          name: "Vodafone Group Pension Scheme (VGPS)",
          value: "VGPS",
          url: "/db/vgps",
          isabledInQuestion2: false
        },
        {
          name: "THUS Group plc Pension Scheme (THUS)",
          value: "THUS",
          url: "/db/thus",
          disabledInQuestion2: false
        },
        {
          name: "Former J O Grant & Taylor Scheme (JOG&T)",
          new_name: "Vodafone Group Pension Scheme (VGPS)",
          value: "VGPS",
          url: "/db/vgps",
          disabledInQuestion2: false
        }
      ],
      currentUserSchemes: {
        lifesight: false,
        vgps: false,
        thus: false,
        message: null,
        isChatboxUser: true,
        customButton: { show: false },
      },
      customButton: { show: false },
      showSchemeLinks: false,
      schemesInfoJson: "",
      thusCompanies: [
        "Scottish Power",
        "Manweb",
        "Southern Water",
        "United Utilities"
      ],
      vgpsCompanies: [
        "Vodafone Group (pre 1 January 2006)",
        "Cable and Wireless Worldwide"
      ],
      checkedCompanyTHUS: null,
      checkedCompanyALL: null,
      constants: {
        notInSchemeYet: 'I\'m not in the scheme yet',
        skipToAllSchemes: 'Skip to all schemes'
      }
    };
  },
  computed: {
    questions() {
      return {
        one: "Do you know which pension scheme(s) you are in?",
        two:
          "Great! Which scheme(s) are you in?",
        three:
          "No problem, let’s figure it out. Are you currently paying into a Vodafone pension scheme?",
        four: "Did you start working for Vodafone on or after 1 January 2006?",
        five: `You could be in either ${this.lifesight.name} or ${this.thus.name}. Did you work for any of the below companies when they were acquired by Vodafone?`,
        six: `You could be in either ${this.vgps.name} or ${this.thus.name}. Did you work for any of the below companies when they were acquired by Vodafone?`,
      };
    },
    lifesight() {
      return this.schemes[0];
    },
    vgps() {
      return this.schemes[1];
    },
    thus() {
      return this.schemes[2];
    }
  },
  methods: {
    // lifesight and VGPS are the only allowed combination
    handleKnowsWhichSchemeCheck() {
      if (this.checkedSchemes.indexOf(this.vgps) !== -1
        || this.checkedSchemes.indexOf(this.lifesight) !== -1)
      {
        this.thus.disabledInQuestion2 = true;
        this.jgts.disabledInQuestion2 = true;

      } else if (this.checkedSchemes.indexOf(this.thus) !== -1) {
        this.vgps.disabledInQuestion2 = true;
        this.lifesight.disabledInQuestion2 = true;

        this.jgts.disabledInQuestion2 = true;
      } else if (this.checkedSchemes.indexOf(this.jgts) !== -1) {
        this.vgps.disabledInQuestion2 = true;
        this.lifesight.disabledInQuestion2 = true;
        this.thus.disabledInQuestion2 = true;

      } else /* ie if none selected */ {
        this.schemes.forEach(scheme => {
          scheme.disabledInQuestion2 = false;
        });

      }
    },
    resetChat() {
      this.schemes.forEach(scheme => {
        scheme.disabledInQuestion2 = false;
      });
      this.knowsWhichScheme = null;
      this.isCurrentlyPayingIn = null;
      this.startedWorkingForVodafoneBeforeJan06 = null;
      this.checkedCompanyTHUS = null;
      this.checkedCompanyALL = null;
      this.checkedSchemes = [];
      this.currentQuestion = 1;
      this.currentUserSchemes = {
        lifesight: false,
        vgps: false,
        thus: false,
        message: null,
        isChatboxUser: true,
        customButton: { show: false },
      };
      this.showSchemeLinks = false;
    },
    goToQuestion(questionNumber) {
      this.currentQuestion = questionNumber;
    },
    setSchemesCookie() {
      // do this.$cookies.set("schemes", JSON.stringify(this.currentUserSchemes), '-1'); for expiry when browser closes
      this.$cookies.set("schemes", JSON.stringify(this.currentUserSchemes));
    },
    submitQuestionOne() {
      if (this.knowsWhichScheme === this.constants.notInSchemeYet) {
        this.currentUserSchemes.message = `OK, find out why it pays to join ${this.lifesight.name}.`;
        this.currentUserSchemes.lifesight = true;
        this.endChat();
      } else if (this.knowsWhichScheme === this.constants.skipToAllSchemes) {
        window.location.href = '/all_schemes';
      } else if (this.knowsWhichScheme === true) {
        this.currentQuestion = 2;
      } else {
        this.currentQuestion = 3;
      }
    },
    submitQuestionTwo() {
      this.checkedSchemes.forEach(scheme => {
        if (scheme.value === "LifeSight") {
          this.currentUserSchemes.lifesight = true;
        } else if (scheme.value === "VGPS") {
          this.currentUserSchemes.vgps = true;
        } else if (scheme.value === "THUS") {
          this.currentUserSchemes.thus = true;
        }
      }); // above probably wants refactoring as very ugly

      const schemeNames = this.checkedSchemes.map(scheme => scheme.new_name || scheme.name);
      let schemeNamesText;
      if (schemeNames.length > 1) {
        schemeNamesText = `${schemeNames
          .slice(0, -1)
          .join(", ")} and ${schemeNames.slice(-1)}`;
      } else {
        schemeNamesText = schemeNames[0];
      }
      this.currentUserSchemes.message = `You are in ${schemeNamesText}.`;
      this.endChat();
    },
    submitQuestionThree() {
      if (this.isCurrentlyPayingIn) {
        this.currentUserSchemes.customButton = {
          show: true,
          href: '/all_schemes',
          text: 'Go to all schemes'
        };
        this.currentUserSchemes.message = `You are probably in ${this.lifesight.name}. If you started working for Vodafone before 1 January 2006, you may also have benefits in ${this.vgps.name}. Please refer to your scheme documents if you are not sure.`;
        this.currentUserSchemes.lifesight = true;
        this.endChat();
      } else {
        this.currentQuestion = 4;
      }
    },
    submitQuestionFour() {
      if (this.startedWorkingForVodafoneBeforeJan06) {
        this.currentQuestion = 5
      } else {
        this.currentQuestion = 6;
      }
    },
    submitQuestionFive() {
      const hasWorkedForThusCompany =
        this.thusCompanies.indexOf(this.checkedCompanyTHUS) !== -1;

       if (hasWorkedForThusCompany) {
        this.currentUserSchemes.message = `You are in ${this.thus.name}.`;
        this.currentUserSchemes.thus = true;
      } else {
        this.currentUserSchemes.message = `You are in ${this.lifesight.name}.`;
        this.currentUserSchemes.lifesight = true;
      }

      this.endChat();
    },

    submitQuestionSix() {
      const hasWorkedForVgpsCompany =
        this.vgpsCompanies.indexOf(this.checkedCompanyALL) !== -1;
      const hasWorkedForThusCompany =
        this.thusCompanies.indexOf(this.checkedCompanyALL) !== -1;

      if (hasWorkedForThusCompany) {
        this.currentUserSchemes.message = `You could be in ${this.thus.name}. You may be in this Scheme if you previously worked for Scottish Power. Manweb, Southern Water or United Utilities.`
        this.currentUserSchemes.thus = true;
      } else if (hasWorkedForVgpsCompany) {
        this.currentUserSchemes.message = `You could be in ${this.vgps.name}. This Scheme opened on 1 November 1988 and closed to new joiners on 31 December 2005.`
        this.currentUserSchemes.vgps = true;
      } else {
        this.currentUserSchemes.customButton = {
          show: true,
          href: '/contact_us',
          text: 'Contact us'
        };
        this.currentUserSchemes.message =
          "I’m sorry, we cannot establish which scheme you are in, please refer to your scheme documents.";
      }

      this.endChat();
    },


    endChat() {
      this.currentQuestion = null;
      this.setSchemesCookie();
      this.showSchemeLinks = true;
    },
    setSchemesIfCookieExists() {
      const schemesFromCookies = this.$cookies.get("schemes");
      if (schemesFromCookies) {
        this.currentUserSchemes = schemesFromCookies;
        this.currentQuestion = null;
        this.showSchemeLinks = true;
      }
    }
  },
  created() {
    this.setSchemesIfCookieExists();
  }
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
header {
  position: relative;
  padding: 30px;
  background-color: #e60000;
  text-align: center;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1,
  h2 {
    color: white;
  }
  h1 {
    font-size: 2.5rem;
    margin-top: 0.25rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: normal;
    max-width: 700px;
    line-height: 1.2;
  }
  &::after {
    content: "";
    position: absolute;
    left: calc(50% - 30px);
    bottom: -30px;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    background-color: transparent;
    border-top: 30px solid #e60000;
  }

  .tobi-image {
    display: inline-block;
    background-image: url("images/tobi.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 70px;
    height: 70px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
  }
}

.chatbox-container {
  padding: 20px 20px 20px 70px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.scheme-links {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  padding-bottom: 30px;
  a {
    margin: 10px 0;
    padding: 11px 0;
  }
}

.form-buttons {
  display: flex;
  padding-top: 20px;
  button {
    cursor: pointer;
    width: 50%;
    font-family: "Vodafone";
  }
  .back-button {
    margin-right: 10px;
  }
  .continue-button {
    margin-left: auto;
  }
}

.option-bubble {
  display: block;
  background-color: #cac6c6;
  padding: 20px;
  padding-left: 60px;
  border-radius: 12px 12px 12px 12px;
  width: 100%;
  color: black;
  font-size: 1.2rem;
  position: relative;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  &:hover,
  &.selected {
    background-color: darken(#cac6c6, 10);
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .box {
    position: absolute;
    border-radius: 100%;
    border: 1px solid #4a4d4e;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    height: 25px;
    width: 25px;
    background-color: white;
    &:hover {
      background-color: darken(white, 5%);
    }
    &--squares {
      border-radius: 0;
    }
  }
  input:checked + .box {
    background-color: #a8b400;
  }
  &--disabled {
    cursor: not-allowed;
    // done this way with rgba opacity rather than just using opacity because of the keyframes
    background-color: rgba(202, 198, 198, 0.4);
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: rgba(202, 198, 198, 0.4);
      color: rgba(0, 0, 0, 0.6);
    }
    .box,
    .box:hover {
      background-color: rgba(202, 198, 198, 0);
      border: 1px solid rgba(74, 77, 78, 0.4);
    }
  }
}

/** fadeInRight **/

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translatex(100px);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translatey(-10px);
  }
  to {
    opacity: 1;
    transform: translatey(0);
  }
}
.in-down {
  animation-name: fadeInDown;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-delay: 0s;
}
.in-right {
  animation-name: fadeInRight;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-delay: 1.5s;
}
</style>
