<template>
  <div class='speech-bubble-container in-left'>
    <div class='speech-bubble'>
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      test: "hello"
    };
  },
  computed: {},
  methods: {},
  created() {}
};
</script>

<style lang="scss" scoped>
.speech-bubble-container {
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    background-image: url("../images/tobi.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: -60px;
    right: 0;
  }
}
.speech-bubble {
  background-color: #00bcd4;
  padding: 20px;
  border-radius: 0px 12px 12px 12px;
  width: 100%;
  color: black;
  font-size: 1.2rem;
  display: inline-block;
}
// .speech-bubble-icon {
//     display: inline-block;
//     background-image: url('../images/tobi.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     width: 50px;
//     height: 50px;
//     margin-right: 10px;
// }

/** fadeInLeft **/

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translatex(-100px);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}
.in-left {
  animation-name: fadeInLeft;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
}
</style>
