/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../SchemeSelector.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     render: h => h(App)
//   }).$mount()
//   document.body.appendChild('#scheme-selector')

//   console.log(app.$el)
// })


// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//


// import Vue from 'vue/dist/vue.esm'
// import App from '../SchemeSelector.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#scheme-selector',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':

// yarn add vue-turbolinks

// Then uncomment the code block below:

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import SchemeSelectionChatbox from '../SchemeSelectionChatbox.vue'
import VueCookies from 'vue-cookies'

Vue.use(TurbolinksAdapter)
Vue.use(VueCookies)

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('scheme-selection-chatbox')) {
    const app = new Vue({
      el: '#scheme-selection-chatbox',
      template: '<SchemeSelectionChatbox />',
      components: { SchemeSelectionChatbox }
    })
  }
})