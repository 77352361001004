function setTabInfographicEvtListeners() {
  const infographics = getTabInfographics();

  infographics.forEach(infographic => {
    infographicButtons = [...infographic.querySelectorAll('.tabs-infographic__button')];

    infographicButtons.forEach(infographicButton => {
      infographicButton.addEventListener('click', () => {
        displaySelectionContents(infographicButton.classList[1], infographic);
      });

    });

  });
}

function selectFirstItem() {
  const infographics = getTabInfographics();

  infographics.forEach(infographic => {
    const firstButton = infographic.querySelector('.tabs-infographic__button');
    displaySelectionContents(firstButton.classList[1], infographic);
  });
}

function displaySelectionContents(className, infographic) {
  hideAllItems(infographic);
  const itemsToDisplay = [...infographic.querySelectorAll(`.${className}`)];
  itemsToDisplay.forEach(item => {
    item.classList.add('active');
  })
}

function hideAllItems(infographic) {
  const timelineItems = [...infographic.querySelectorAll('.tabs-infographic__tab-content, .tabs-infographic__button')];
  timelineItems.forEach(timelineItem => {
    if (timelineItem.classList.contains('active')) {
      timelineItem.classList.remove('active');
    }
  })
}

function getTabInfographics() {
  return [...document.querySelectorAll('.tabs-infographic')];
}

document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.tabs-infographic')) {
    setTabInfographicEvtListeners();
    selectFirstItem();
  }
})